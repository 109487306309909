$icons: (
    'br': 0 0 48px 33px,
    'en': 0 -33px 48px 33px,
    'es': 0 -66px 49px 33px,
    'ic-email': 0 -99px 25px 24px,
    'ic-face': 0 -123px 40px 40px,
    'ic-marker': 0 -163px 25px 24px,
    'ic-movel': 0 -187px 25px 24px,
    'ic-phone': 0 -211px 25px 24px,
);

.icon{
    display:inline-block;
    background-image: url(#{$imgs}/sprite.png);
    background-respeat:no-repeat;
}

@mixin sprites($icons) {
    @each $icon,$data in $icons{
        .#{$icon}{
            width: nth($data,3);
            height: nth($data,4);
            background-position: #{nth($data,1)} #{nth($data,2)};
        }
    }
}

@include sprites($icons);