.wrapper{
	min-height:100vh;
	background: $bg-wrapper;
	transition: background-position .1s ease;
}

html{
	overflow-x:hidden;
}

.container{
	max-width:1000px;
}