.banners{
	.pagers-control{
		.pager{
			position: absolute;
			z-index: 19;
			top: 50%;
			transform: translateY(-50%);

			&.bg-white{
				background: #FFF;

				.fa{
					color: #064078;
				}
			}

			&.pager-prev{
				left: calc(50% - 500px);
			}
			&.pager-next{
				right: calc(50% - 500px);
			}
		}
	}
}
.solucoes{
	position: relative;
	min-height: 400px;
	margin: 40px 0;
	background: $bg-solucoes;

	display: flex;
	justify-content: center;
	align-items: center;
}

.servico-default{
	position: relative;
	background: #FFF;
	&:hover{
		border-color: #C7C7C7;
		.heading{
			border-color:#009933; 
			background: #009933;
		}
		.body{
			border-bottom-color: #009933;
		}
	}
	border: 2px solid transparent;
	
	.heading{
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
		padding: 30px 20px;
		font-family: "open_sansbold";
		color: #FFF;
		border: 2px solid #C7C7C7;
		border-bottom: 3px solid #009933;
	
		@include resumo(18px, 1.2, 2);
		background: #008080;
	}
	
	.photo{
		position: relative;
		padding-bottom: (102/301) * 100%;
		.picture{
			position: absolute;
			width: 100%;
			height: 100%;

			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
	.body{
		padding: 0 10px;
		border-bottom: 3px solid transparent;

		.text{
			font-family: "open_sansregular";
			color: #3F3F3F;
			@include resumo(14px, 1.2, 6);
		}

		.link{
			font-family: "open_sansregular";
			font-size: 13px;
			color: #1C558E;
			margin-top: 40px;
		}
	}
}

#carousel-parceiros{
	.parceiro-default{
		position: relative;
		padding-bottom: (137/198)*100%;

		.picture{
			position: absolute;
			width: 100%;
			height: 100%;

			display: flex;
			justify-content: center;
			align-items: center;

			img{
				display: inline-block;
			}
		}
	}
}

.subtitle{
	font-family: "open_sansregular";
	font-size: 18px;
}

.c-blue{
	color: #064078;
}

.parceiros{
	opacity: 0;

	&.animated{
		opacity: 1;
		@extend .bounceInUp;
		animation-duration: 1s;
		animation-delay: .2s;
		animation-fill-mode: forwards;
	}
}

#carousel-solucoes{
	.owl-nav{
		text-align:center;
		margin-top:40px;
		
		& > *{
			display: inline-block;
		    margin: 0 5px;
		    padding: 5px 10px;
		    color: #FFF;
		    background: #1C558E;
		    cursor:pointer;
		}
	}
}