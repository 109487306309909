.rodape{
	position: relative;
	padding-top: 80px;
	background: $bg-rodape;
	background-size: cover;

	h3{
		font-family: "JuraDemi-Bold";
		font-size: 32px;
		color: #FFF;

		&:after{
			content: "";
			display: block;
			width: 80px;
			height: 5px;
			margin-top: 10px;
			background: #00468C;
		}
	}

	.contatos{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center;

		span{
			display: block;
			margin-bottom: 35px;
			&:last-child{
				margin-bottom: 15px;
			}

			font-family: "open_sansregular";
			font-size: 14px;
			color: #FFF;

			.icon,
			.fa{
				position: relative;
				top: 7px;
				margin-right: 10px;
			}
		}
	}

	.creditos{
		margin-top: 20px;
		padding: 10px 0;
		border-top: 2px solid rgba(#00468C, .2);
		@include clearfix();

		font-family: Verdana, Sans-serif;
		font-size: 11px;
		color: #FFF;

		.logo-gv8{
			position: relative;
			margin-left: 5px;
			top: -2.5px;
		}
	}

	.hash{
		font-family: "open_sansregular";
		font-size: 15px;
		color: #FFF;
		font-weight: bold;
	}
}

.politica__privacidade{
	position: fixed;
	bottom: 15px;
	left: 50%;
	transform: translate(-50%, 0);
	z-index: 999;
	background: #FFF;
	border-radius: 4px;
	display: grid;
	grid-template-columns: 1fr min-content;
	width: calc(100% - 30px);
	max-width: 1140px;
	padding: 10px;
	grid-gap:10px;
	box-shadow: 0 0 8px rgba(#000,.8);

	a{
		display: inline-block;
		color: #45b056;
		text-decoration: underline;
		font-weight: bold;
	}

	.btn{
		white-space: nowrap;
		@include button-variant(#FFF, #45b056, #45b056);
		font-family: "JuraDemi-Bold";
		font-weight: 900;
		text-shadow: -1px 1px rgba(#000,.4);
	}
}