.unlistenner{
	list-style: none;
	padding-left: 0;
	margin-bottom: 0;
}

.border-left-white{
	padding-left: 60px;
	border-left: 1px solid #FFF;
}

.owl-item img{
	width: auto !important;
}

.pagers-control{
	text-align: center;

	.pager{
		display: inline-block;
		margin: 0 5px;
		padding: 5px 10px;
		color: #FFF;
		background: #1C558E;
	}
}

.btn-green{
	color: #FFF;
	background: $cor-default;

	&:hover{
		color: #FFF;
		@include gradient-vertical($cor-default, lighten($cor-default, 10%));
	}
}


.carousel-fade {
    .carousel-inner {
        .item {
            transition-property: opacity;
        }
        
        .item,
        .active.left,
        .active.right {
            opacity: 0;
        }

        .active,
        .next.left,
        .prev.right {
            opacity: 1;
        }

        .next,
        .prev,
        .active.left,
        .active.right {
            left: 0;
            transform: translate3d(0, 0, 0);
        }
    }

    .carousel-control {
        z-index: 2;
    }
}