.navbar-topo{
	position: relative;
	background: $bg-header;

	.navbar-address{
		position: relative;
		text-align: right;
		padding: 10px 0;
		background: $bg-address;

		span{
			font-family: "JuraDemi-Bold";
			font-size: 14px;
			color: #18291D;
		}
	}

	.navbar-header{
		position: relative;
		top: -30px;

		.logo{
			position: relative;
			z-index: 10;
		}

		&:before,
		&:after{
			content: "";
			display: inline-block;
			position: absolute;
			top: 0;
		}
		&:before{
			width: 1000px;
			height: 100%;
			right: 0;
			background: $bg-address;
		}

		&:after{
			left: 100%;
			border-top: 60px solid $bg-address;
			border-right: 20px solid transparent;
			border-bottom: 60px solid transparent;
			border-left: 20px solid $bg-address;
		}
	}

	.navbar-nav{
		float: none;
		@media (max-width: $screen-xs-max){
			margin-top: 40px;
		}
		#menu{
			@media (min-width: $screen-sm-min){
				display: flex;
				justify-content: space-between;
				align-items: center;

				margin-top: 30px;
			}
			
			&.load{
				li{
					@extend .fadeInDown;
					animation-duration: 1s;
					animation-fill-mode: forwards;
				}

			}
			li{
				position: relative;
				opacity: 0;
				&:before{
					content: "";
					
					display: inline-block;
					width: 10%;
					height: 0;

					position: absolute;
					left: 50%;
					top: 50%;

					transform: translateX(-50%) translateY(-50%);

					border-color: #008080;
					background: #009933;

					// transition: width .16s ease;
					// transition-delay: 1s;
				}
				
				@for $i from 1 through 4 {
					&:nth-child(#{$i}){
						animation-delay: #{$i * .2}s;
					}
				}


				a{
					position: relative;
					z-index: 19;
					display: block;
					font-family: "JuraDemi-Bold";
					font-size: 18px;
					padding: 10px;
					@media (min-width: $screen-sm-min) and (max-width: $screen-sm-max){
						padding: 10px 5px;
					}
					color: #3F3F3F;
					border: 1px solid transparent;
					transition: color .16s ease;

					transition: all .16s ease;
				}

				&:hover,
				&.active{
					a{
						text-decoration: none;
						color: #FFF;
					}
				}
			}
		}
	}

	.navbar-toggle{
		color: $cor-default;
		border: 1px solid $cor-default;
	}
}


.mouse-enter{
	&:before{
		animation: hover-enter .48s forwards ease;
	}
}


.mouse-leave{
	&:before{
		animation: hover-out .48s forwards ease;
	}
}

@keyframes hover-enter {
	0% {
		width: 5%;
		height: 0%;
	}
	50%{
		width: 5%;
		height: 100%;	
	}
	100% {
		width: 100%;
		height: 100%;
	}
}

@keyframes hover-out {
	0% {
		width: 100%;
		height: 100%;
	}
	50%{
		width: 5%;
		height: 100%;	
	}
	100% {
		width: 5%;
		height: 0%;
	}
}