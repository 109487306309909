a{
	color:inherit;
	&:hover{
		color: inherit;
		text-decoration: none;
	}
}

.paginacao{
	text-align: center;
}

.title-internas{
	margin-top:0;
}

.page-header{
	padding-bottom: 0;
	border-bottom: 0;

	h1{
		font-family: "JuraDemi-Bold";
		font-size: 32px;
		color: #003900;

		&:after{
			content: "";
			display: block;
			width: 80px;
			height: 5px;
			margin-top: 10px;
			background: #00468C;
		}
	}
}